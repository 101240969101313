import styled from "@emotion/styled";
import Layout from "components/Layout";
import { useBoUserAuthContext } from "context/BoUserAuthContext";
import { KeyboardEvent, ReactElement, useState } from "react";
import { Button, TextField } from "ui";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 90vh;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 20px;
  text-align: center;
  max-width: 400px;
`;

const ErrMsg = styled.p`
  background-color: #ffcccb;
  color: red;
  font-size: 12px;
  padding: 10px 20px;
  border-radius: 4px;
  margin-top: 0;
`;

const SubRedirectText = styled.p`
  text-align: center;
  font-size: 14px;
`;

const Login = () => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");
  const { user, login } = useBoUserAuthContext();

  const submit = async () => {
    if (!email) return;
    const res = await login(email, pw);
    if (res?.error) {
      setError(res.error);
    }
  };

  const handlePasswordKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      submit();
    }
  };

  return (
    <Wrapper>
      <Content>
        <div>
          <h2>Välkommen</h2>
          <p>Här kan du som anställd logga in till din salong</p>
        </div>
        <div>
          <TextField
            variant="outlined"
            label="Email"
            type="email"
            value={email}
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="Lösenord"
            type="password"
            value={pw}
            fullWidth
            onChange={(e) => setPw(e.target.value)}
            onKeyDown={handlePasswordKeyDown}
          />
        </div>
        <div>
          {error && <ErrMsg>{error}</ErrMsg>}
          <Button
            aria-label="logga in"
            variant="contained"
            fullWidth
            onClick={submit}
            loading={user?.loading}
          >
            Logga in
          </Button>
        </div>
        <SubRedirectText>
          Är du privat person och har ett befintligt abonnemang? Logga in{" "}
          <a href={process.env.NEXT_PUBLIC_SUB_APP_URL}>här</a>
        </SubRedirectText>
      </Content>
    </Wrapper>
  );
};

Login.getLayout = function getLayout(page: ReactElement) {
  return <Layout headerLinks={false}>{page}</Layout>;
};

export default Login;
